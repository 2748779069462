import React from 'react';

import AnchorLink from 'react-anchor-link-smooth-scroll';
import LogoIcon from '../../svg/LogoIcon';
import { useState } from 'react';

const linkStyle = {
  color: '#1a202c',
  textDecorationLine: 'underline'
};
const Navbar = () => {
  const [active, setActive] = useState(false);

  const handleClick = () => {
    setActive(!active);
  };

  return (
    <header className="sticky top-0 lg:px-16 px-6 bg-white flex flex-wrap items-center lg:py-4 py-3 shadow">
      <div className="flex-1 flex justify-between items-center">
        <LogoIcon />
      </div>

      <button className="pt-2 lg:hidden block focus:outline-none" onClick={handleClick}>
        <svg
          className="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className={`${active ? 'hidden' : ''}`}
            fill-rule="evenodd"
            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
            clip-rule="evenodd"
          ></path>
          <path
            className={`${active ? '' : 'hidden'}`}
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"
          ></path>
          {/* <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M4 6h16M4 12h16M4 18h16"
          /> */}
        </svg>
      </button>

      <div className={`${active ? '' : 'hidden'}   w-full lg:inline-flex lg:flex-grow lg:w-auto`}>
        <div className="lg:inline-flex lg:flex-row lg:ml-auto lg:w-auto w-full lg:items-center items-start  flex flex-col lg:h-auto">
          <AnchorLink style={linkStyle} className="px-4 pt-3" href="#features">
            Engagement
          </AnchorLink>
          <AnchorLink style={linkStyle} className="px-4 pt-3" href="#services">
            Méthodologie
          </AnchorLink>
          <AnchorLink style={linkStyle} className="px-4 pt-3" href="#stats">
            Résultats
          </AnchorLink>
          <AnchorLink style={linkStyle} className="px-4 pt-3" href="#testimonials">
            L'équipe
          </AnchorLink>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
