import React from 'react';

const Footer = () => (
  <footer className="container mx-auto pt-16 pb-6 px-3 mt-24 mb-8 text-gray-800">
    <div className="flex -mx-3">
      <div className="flex-1 ml-3 mr-6">
        <h2 className="text-lg font-semibold">A propos de nous</h2>
        <p className="mt-5">
          "Never walk alone", ceci est notre dévise. Nous croyons dans vos idées, ne vous limitez
          pas par manque de moyens humains, Agile Start sera toujours à vos côtés !
        </p>
      </div>
      {/* <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">Important Links</h2>
        <ul className="mt-4 leading-loose">
          <li>
            <a href="https://codebushi.com">Terms &amp; Conditions</a>
          </li>
          <li>
            <a href="https://codebushi.com">Privacy Policy</a>
          </li>
        </ul>
      </div> */}
      <div className="flex-1 mx-6">
        <h2 className="text-lg font-semibold">Contact</h2>
        <ul className="mt-4 leading-loose">
          <li>Siège Social</li>
          <li>2 Rue Horace Vernet, 92130 Issy les Moulineaux</li>
          <li>Tél: +33 679 50 45 77</li>
        </ul>
      </div>
    </div>
    <div className="flex justify-center text-sm pt-16">
      <p>Site web réalisé par Agile Start © COPYRIGHT 2021 - Tous Droits Réservés</p>
    </div>
  </footer>
);

export default Footer;
