import hugo from '../assets/hugo.jpg';
import janneth from '../assets/janneth.jpg';

export default [
  {
    title: 'Hugo Mejia',
    content: 'Enetrepreneur et dirigeant, responsable de la direction créative et commerciale',
    customerName: 'Direction Creative et Commerciale',
    customerTitle:
      "Expérience de design d'interfaces et expériences des utilisateurs, Développement Front-end et Mobile.",
    customerImage: hugo
  },
  {
    title: 'Janneth Rodriguez',
    content:
      "Chef de projets informatiques avec plus de 15 d'expérience en gestion de projets informatiques.",
    customerName: 'Direction technique',
    customerTitle:
      "Expérience en projets Web (PHP, NodeJS), Experte en systèmes d'information et base de données",
    customerImage: janneth
  }
];
