import React from 'react';
import analyze from '../assets/analyze.svg';
import scrum from '../assets/scrum.svg';
import build from '../assets/build.svg';

const AgileImg = ({ action }) => {
  let img;
  if (action == 'analyze') {
    img = analyze;
  } else if (action == 'scrum') {
    img = scrum;
  } else {
    img = build;
  }
  return <img className="mx-auto" src={img} alt={`action agile ${action}`}></img>;
};

export default AgileImg;
