import React from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import customerData from '../data/customer-data';
import NewHero from '../svg/NewHero';
// import SvgCharts from '../svg/SvgCharts';
import AgileImg from '../svg/agileimage';

export default () => (
  <Layout>
    <section className="pt-20 md:pt-40">
      <div className="container mx-auto px-8 lg:flex">
        <div className="text-center pt-4 mr-3 lg:text-left lg:w-2/5">
          <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
            L'imagination n'a pas de limites, tes rêves non plus
          </h1>
          <p className="text-xl lg:text-2xl mt-6 font-light">
            Développement d'applications informatiques web et mobiles sur mésure pour entreprises
            innovantes
          </p>
          {/* <p className="mt-8 md:mt-12">
            <Button className="font-bold " size="lg">
              Contactez nous
            </Button>
          </p>
          <p className="mt-4 text-gray-600">Besoin de discuter sur votre besoin ?</p> */}
        </div>
        <div className="lg:w-3/5">
          <NewHero />
        </div>
      </div>
    </section>
    <section id="features" className="py-20 lg:pb-40 lg:pt-48">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-5xl font-semibold">Nos Engagements</h2>
        <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
          <div className="flex flex-col flex-1 px-3">
            <Card className="flex-1 mb-8">
              <p className="font-semibold text-xl">Qualité sans égale</p>
              <p className="mt-4">
                Nos équipes comptent plus de 15 d'expérience professionnelle en gestion de projets
                informatiques.
              </p>
            </Card>
          </div>
          <div className="flex flex-col flex-1 px-3">
            <Card className="flex-1 mb-8">
              <p className="font-semibold text-xl">Respect de délais</p>
              <p className="mt-4">
                Peu importe le délai, nous allons travailler sans relâche, aucun deadline ne sera
                dépassé. Jamais.
              </p>
            </Card>
          </div>
          <div className="flex flex-col flex-1 px-3">
            <Card className="flex-1 mb-8">
              <p className="font-semibold text-xl">Solutions sur mésure</p>
              <p className="mt-4">
                Simple site web, app mobile ou application complexe, nous serons toujours là pour
                vous.
              </p>
            </Card>
          </div>
        </div>
      </div>
    </section>

    <section className="container mx-auto text-center" id="services">
      <h3 className="text-2xl lg:text-4xl font-semibold">Une méthodologie Agile</h3>
    </section>
    <SplitSection
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">Analyse de besoins</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Un membre de notre équipe écoutera attentivement ton besoin, puis fera une analyse de
            tes outils actuels afin d'étudier la meilleure solution à ton problème.
          </p>
        </div>
      }
      secondarySlot={<AgileImg action="analyze" />}
    />
    <SplitSection
      reverseOrder
      primarySlot={
        <div className="lg:pl-32 xl:pl-48">
          <h3 className="text-3xl font-semibold leading-tight">
            Conception et planification du travail
          </h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Aprés l'analyse de ton besoin, nous te ferons part de notre proposition technique et
            grâce à une évaluation detaillée de la charge de travail, nous estimerons le budget et
            le délai de réalisation de ton projet.
          </p>
        </div>
      }
      secondarySlot={<AgileImg action="scrum" />}
    />
    <SplitSection
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">
            Co-construction et tests des utilisateurs
          </h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Le travail planifié sera éxecuté par cycles de 1 à 2 semaines, pendant lesquels tes
            équipes participeront de façon active et proactive à la réalisation du produit de tes
            rêves. A la fin du projet, celui-ci sera mis à l'éprouve par les utilisateurs finaux
            afin de garantir l'atteinte de résultats attendus.
          </p>
        </div>
      }
      secondarySlot={<AgileImg action="build" />}
    />
    <section id="stats" className="py-20 lg:pt-32">
      <div className="container mx-auto text-center">
        <LabelText className="text-gray-600">Nos résultats parlent par eux mêmes</LabelText>
        <div className="flex flex-col sm:flex-row mt-8 lg:px-24">
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="99%" secondaryText="Clients satisfaits" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="85%" secondaryText="Amélioration de l'expérience utilisateur" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="7500" secondaryText="Héures de développement fournies en 2020" />
          </div>
        </div>
      </div>
    </section>
    <section id="testimonials" className="py-10 lg:pt-30">
      <div className="container mx-auto">
        <LabelText className="mb-8 text-gray-600 text-center">
          L'équipe Agile Start, une mélange de créativité et d'expérience
        </LabelText>
        <div className="flex flex-col md:flex-row md:-mx-3">
          {customerData.map(customer => (
            <div key={customer.customerName} className="flex flex-col flex-1 px-3">
              <CustomerCard customer={customer} />
            </div>
          ))}
        </div>
      </div>
    </section>
    <section className="container mx-auto my-10 lg:my-20 py-16 lg:py-24 bg-gray-200 rounded-lg text-center">
      <div className="max-w-prose md:mx-22 sm:mx-12">
        <h3 className="text-5xl font-semibold">Prết à developper l'application de tes rêves?</h3>
        <p className="mt-8 text-xl font-light">
          N'hésites pas à nous contacter en remplissant le formulaire ci-dessous.
        </p>
      </div>

      <div className="flex justify-center">
        <form
          className="sm:w-full md:w-3/4 lg:w-1/2 sm:px-8 object-top lg:mt-12 sm:mt-10"
          action="https://getform.io/f/fe4f35ba-7ee3-4a2d-a202-9ed5f0280dab"
          method="POST"
        >
          <div className="w-full flex flex-col flex-start px-3 pt-6">
            <label className="text-left uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Nom
            </label>
            <input
              className="block bg-white-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-teal-500"
              id="nick"
              type="text"
              name="name"
            />
          </div>

          <div className="w-full flex flex-col flex-start px-3 pt-6">
            <label className="text-left block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              E-mail
            </label>
            <input
              className="appearance-none block  bg-white-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-teal-500"
              id="email"
              type="email"
              name="email"
            />
          </div>
          <div className="w-full flex flex-col flex-start px-3 pt-6">
            <label className="text-left block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Message
            </label>
            <textarea
              className=" no-resize block bg-white-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-teal-500 h-48 resize-none"
              id="message"
              placeholder="Ton message..."
              name="message"
            />
          </div>
          <div className="md:flex md:items-center px-3 pt-6">
            <Button className="text-white font-bold py-2 px-4 rounded" type="submit">
              Envoyer
            </Button>
          </div>
        </form>
      </div>
    </section>
  </Layout>
);
